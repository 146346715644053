import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker, Circle } from 'google-maps-react';
import Service from "../utils/networkutils";
import { decryptData, getCookie, setCookie } from '../utils/CookieHelper';
import moment from 'moment';
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemText, Slide, TextField, Toolbar, Typography, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import CloseIcon from '@material-ui/icons/Close';
import ReactGA from 'react-ga';

var init_aud, avail_d1_18, avail_d2_18, avail_d1_45, avail_d2_45 = null

const trackingId = "UA-156664293-2";

ReactGA.initialize(trackingId, { debug: false });

// ReactGA.initialize(trackingId, { debug: false });

ReactGA.pageview("/");

var fernet = require('fernet');

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});



let mapStyle = [{
    "elementType": "geometry",
    "stylers": [{
        "color": "#212121"
    }]
}, {
    "elementType": "labels.icon",
    "stylers": [{
        "visibility": "off"
    }]
}, {
    "elementType": "labels.text.fill",
    "stylers": [{
        "color": "#757575"
    }]
}, {
    "elementType": "labels.text.stroke",
    "stylers": [{
        "color": "#212121"
    }]
}, {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [{
        "color": "#757575"
    }]
}, {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [{
        "color": "#9e9e9e"
    }]
}, {
    "featureType": "administrative.land_parcel",
    "stylers": [{
        "visibility": "off"
    }]
}, {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [{
        "color": "#bdbdbd"
    }]
}, {
    "featureType": "administrative.neighborhood",
    "stylers": [{
        "visibility": "off"
    }]
}, {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [{
        "color": "#757575"
    }]
}, {
    "featureType": "poi.business",
    "stylers": [{
        "visibility": "off"
    }]
}, {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [{
        "color": "#181818"
    }]
}, {
    "featureType": "poi.park",
    "elementType": "labels.text",
    "stylers": [{
        "visibility": "off"
    }]
}, {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [{
        "color": "#616161"
    }]
}, {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [{
        "color": "#1b1b1b"
    }]
}, {
    "featureType": "road",
    "stylers": [{
        "visibility": "off"
    }]
}, {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [{
        "color": "#2c2c2c"
    }]
}, {
    "featureType": "road",
    "elementType": "labels",
    "stylers": [{
        "visibility": "off"
    }]
}, {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [{
        "color": "#8a8a8a"
    }]
}, {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [{
        "color": "#373737"
    }]
}, {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [{
        "color": "#3c3c3c"
    }]
}, {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [{
        "color": "#4e4e4e"
    }]
}, {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [{
        "color": "#616161"
    }]
}, {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [{
        "color": "#757575"
    }]
}, {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [{
        "color": "#000000"
    }]
}, {
    "featureType": "water",
    "elementType": "labels.text",
    "stylers": [{
        "visibility": "off"
    }]
}, {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [{
        "color": "#3d3d3d"
    }]
}]

var district_lat_lng = []
var data = []

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class MapView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: [],
            emp: [],
            openVacc: false,
            vaccData: [],
            fullPinData: {},
            selectedData: {},
            run18D1Alert: localStorage.getItem("run18D1Alert") ? true : false,
            run18D2Alert: localStorage.getItem("run18D2Alert") ? true : false,
            run45D1Alert: localStorage.getItem("run45D1Alert") ? true : false,
            run45D2Alert: localStorage.getItem("run45D2Alert") ? true : false,
            openAlert: false,
            initAudio: true,
            getName: null,
            getFullAddress: null,
            getPincode: null,
            openAdd: false,
            openPin: false,
            slotButtonEnable: false
        }
    }


    componentDidMount() {

        init_aud = new Audio("/audio/init_aud.mp3")
        avail_d1_18 = new Audio("/audio/avail_d1_18.mp3")
        avail_d2_18 = new Audio("/audio/avail_d2_18.mp3")
        avail_d1_45 = new Audio("/audio/avail_d1_45.mp3")
        avail_d2_45 = new Audio("/audio/avail_d2_45.mp3")
        // console.log(audio)
        var access = getCookie("bb_covid19_access")
        console.log(window.location.search)
        const query = new URLSearchParams(window.location.search);
        console.log(query)
        var exp = null
        var email = getCookie("bb_covid19_ma")
        // console.log(email)
        try {
            exp = query.get('exp').replace(" ", "+").replace(" ", "+").replace(" ", "+").replace(" ", "+").replace(" ", "+")
            email = query.get('em').replace(" ", "+").replace(" ", "+").replace(" ", "+").replace(" ", "+").replace(" ", "+")
            // console.log(exp, decryptData(exp), exp, (moment(decryptData(exp)) < moment()), !access)
        } catch (error) {

        }

        // console.log(email)

        if ((window.location.host.includes("1loc1alhost:")) || (window.location.host.includes("p1aram.bbedcindia.com"))) {
            this.fetchData()
        } else {
            if (access || exp) {
                if ((!decryptData(exp) || moment(decryptData(exp)) < moment())) {
                    if (access) {
                        this.fetchData()
                    } else {
                        setTimeout(() => {
                            // alert("Access Expired!")
                            window.location = "https://bbparam.com/ext/auth?url=" + window.location.host
                            // window.location.href = "https://bbparam.com"
                        }, 2000);
                    }

                } else {
                    // alert(decryptData(email))
                    setCookie('bb_covid19_access', "allow", { secure: true, 'max-age': 86400, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                    setCookie('bb_covid19_ma', decryptData(email), { secure: true, 'max-age': 86400, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                    window.location.href = "/"
                }


            } else {
                setTimeout(() => {
                    window.location = "https://bbparam.com/ext/auth?url=" + window.location.host
                    // window.location.href = "https://bbparam.com"
                }, 1500);

            }

        }


    }

    fetchData() {

        // setCookie('bb_covid19_access', "allow", { secure: true, 'max-age': 86400, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });



        // Geocode.fromLatLng("48.8583701", "2.2922926").then(
        //     (response) => {
        //       const address = response.results[0].formatted_address;
        //       console.log(address);
        //     },
        //     (error) => {
        //       console.error(error);
        //     }
        //   );
        var email = getCookie("bb_covid19_ma")
        var dataTo = []
        Service.get('https://bb-covid-app.el.r.appspot.com/bb/covid/pro/get_bb_enco/', {
            headers: {

            }
        })
            .then(res => {

                var secret = new fernet.Secret("dkAJjbL09BHfPIygCptJUd6SBcP7BK4y-oZpDimNR2I=");
                var token = new fernet.Token({
                    secret: secret,
                    token: res.data,
                    ttl: 0
                })
                var emp_data = JSON.parse(token.decode());
                for (let i = 0; i < emp_data.length; i++) {
                    const element = emp_data[i];
                    if (email === element.email) {
                        dataTo.push(element)
                        this.getPinData(element, true)
                        this.pingTackingSystem()
                    } else if (email === "all09876543210") {
                        dataTo.push(element)
                    }
                }
                if (dataTo.length === 0) {
                    this.setState({
                        openAdd: true,
                    })
                }
                // console.log(dataTo)
                this.setState({
                    emp: dataTo
                })
            })
            .catch(error => {
                console.log("error1: " + error);
            });



        Service.get('https://data.covid19india.org/v4/min/data.min.json', {
            headers: {

            }
        })
            .then(res => {
                // console.log("MapData\n");
                // console.log(res.data)


                Service.get(process.env.PUBLIC_URL + '/state_code.json', {
                    headers: {
                        // Authorization: "Token " + token
                    }
                })
                    .then(res1 => {
                        Service.get(process.env.PUBLIC_URL + '/district_lat_lng.json', {
                            headers: {
                                // Authorization: "Token " + token
                            }
                        })
                            .then(res2 => {
                                // console.log(res2.data)
                                for (let i = 0; i < res1.data.length; i++) {
                                    const element = res1.data[i];
                                    // console.log(element)
                                    if (res.data[element.ab]) {
                                        var state_data = res.data[element.ab]["districts"]
                                        // console.log(state_data)
                                        var district_data = Object.keys(state_data)
                                        var is_unknow = false

                                        if (district_data.length === 1 && district_data[0] === "Unknown") {
                                            district_data = [element.state]
                                            is_unknow = true
                                            // alert(district_data)
                                        }
                                        // console.log(district_data)
                                        // setTimeout(() => {
                                        for (let j = 0; j < district_data.length; j++) {
                                            const element1 = district_data[j];
                                            district_lat_lng.push({
                                                address: element1 + ", " + element.state,
                                                name: element1,
                                                state: element.state,
                                                code: element.ab

                                            })
                                            // console.log(element1)
                                            var found = false
                                            for (let k = 0; k < res2.data.Sheet1.length; k++) {
                                                const element2 = res2.data.Sheet1[k];
                                                // console.log(element1, element2.name)
                                                if (element1 === element2.name) {
                                                    found = true
                                                    // console.log(element1, element2.name, res.data[element.ab]["districts"])
                                                    if (is_unknow) {
                                                        element2.data = res.data[element.ab]["districts"]["Unknown"]["total"]
                                                    } else {
                                                        element2.data = res.data[element.ab]["districts"][element1]["total"]
                                                    }

                                                    data.push(element2)

                                                }
                                            }

                                            if (!found) {
                                                console.log("No lat lng", element1, element)
                                            }

                                            // this.geoFetch(element1, element)

                                        }
                                        // }, i * 1000);


                                    }

                                }
                                this.setState({
                                    data: data,
                                    isLoading: false,
                                })
                                // console.log(data)
                            })
                            .catch(error => {
                                console.log("error1: " + error.code);
                            });

                        // Geocode.fromAddress("Eiffel Tower").then(
                        //     (response) => {
                        //       const { lat, lng } = response.results[0].geometry.location;
                        //       console.log(lat, lng);

                        //     },
                        //     (error) => {
                        //       console.error(error);
                        //     }
                        //   );
                    })
                    .catch(error => {
                        console.log("error: " + error.code);
                    });


            })
            .catch(error => {
                alert("Something went wrong!")
                console.log("error: " + error.code);
            });

    }

    getPinData(element, openVacc) {
        if (((element.pincode && !element.pincode.includes("#")) || (element.full_address.match(/\d{6}/) || [false][0]))) {
            var pin = ((element.pincode && !element.pincode.includes("#"))) ? element.pincode : (element.full_address.match(/\d{6}/) || [false][0])[0]
            console.log(pin)
            Service.get('https://bb-covid-app.el.r.appspot.com/bb/covid/info/' + pin + "/", {
                headers: {

                }
            })
                .then(res => {
                    console.log(res.data)
                    for (let i = 0; i < res.data.data.centers.length; i++) {
                        const element = res.data.data.centers[i];
                        element.avail_18 = this.available18Dose(element.sessions)
                        element.avail_45 = this.available45Dose(element.sessions)
                    }
                   
                    if (openVacc) {
                        this.setState({
                            openVacc: openVacc ? true : this.state.openVacc,
                            vaccData: res.data.data.centers,
                            fullPinData: res.data,
                            selectedData: element,
                            // slotButtonEnable:true,
                        })
                    } else if (this.state.vaccData.length !== res.data.data.centers.length) {
                        this.setState({
                            openVacc: openVacc ? true : this.state.openVacc,
                            vaccData: res.data.data.centers,
                            fullPinData: res.data,
                            selectedData: element,
                        })
                    } else {
                        for (let i = 0; i < res.data.data.centers.length; i++) {
                            const element = res.data.data.centers[i];
                            for (let j = 0; j < this.state.vaccData.length; j++) {
                                const element1 = this.state.vaccData[j];
                                if (element.center_id === element1.center_id && (element.avail_18 !== element1.avail_18 || element.avail_45 !== element1.avail_45)) {
                                    this.setState({
                                        openVacc: openVacc ? true : this.state.openVacc,
                                        vaccData: res.data.data.centers,
                                        fullPinData: res.data,
                                        selectedData: element,
                                    })
                                }
                            }
                        }
                    }


                    this.checkAlert(res.data.data.centers)

                    setTimeout(() => { this.getPinData(element, false) }, 15000);
                })
                .catch(error => {
                    setTimeout(() => { this.getPinData(element, false) }, 15000);
                    // alert("Something went wrong!")
                    console.log("error: ", error);
                });
        } else {
            this.setState({
                openPin: true
            })
            alert("No pincode in your address. Please contact venkata.rathinam@balfourbeatty.com or yaageshprasad.prakasam@balfourbeatty.com to update the information")
        }
    }

    pingTackingSystem (){
       
        if (this.state.run18D1Alert) {
            ReactGA.pageview("/tracking")
        } else if (this.state.run18D2Alert) {
            ReactGA.pageview("/tracking")
        } else if (this.state.run45D1Alert) {
            ReactGA.pageview("/tracking")
        } else if (this.state.run45D2Alert) {
            ReactGA.pageview("/tracking")
        } else {
            ReactGA.pageview("/")
        }

        setTimeout(() => { this.pingTackingSystem() }, 30000);
    }

    checkAlert(data) {
        var openTab = false
        var audioPlay = []

        if (this.state.run18D1Alert && this.check18Dose1(data)) {
            openTab = true
            audioPlay.push(118)
        }
        if (this.state.run18D2Alert && this.check18Dose2(data)) {
            openTab = true
            audioPlay.push(218)
        }
        if (this.state.run45D1Alert && this.check45Dose1(data)) {
            openTab = true
            audioPlay.push(145)
        }
        if (this.state.run45D2Alert && this.check45Dose2(data)) {
            openTab = true
            audioPlay.push(245)
        }
        if (openTab) {
            this.audioPlaySeries(audioPlay)
            window.open("https://selfregistration.cowin.gov.in/")
        }

    }

    audioPlaySeries(data) {
        if (data[0] === 118) {
            avail_d1_18.play()
        } else if (data[0] === 218) {
            avail_d2_18.play()
        } else if (data[0] === 145) {
            avail_d1_45.play()
        } else if (data[0] === 245) {
            avail_d2_45.play()
        }
        var y = data.splice(1);
        setTimeout(() => { this.audioPlaySeries(y) }, 6400);
    }

    check18Dose1(data) {
        var returnData = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            for (let j = 0; j < element.sessions.length; j++) {
                const element1 = element.sessions[j];
                if (element1.min_age_limit === 18) {
                    returnData += element1.available_capacity_dose1
                }

            }
        }
        if (returnData > 0) {
            this.setState({
                run18D1Alert: false
            })
            localStorage.removeItem("run18D1Alert")
            init_aud.pause()

            return true
        }
        return false
    }

    check18Dose2(data) {
        var returnData = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            for (let j = 0; j < element.sessions.length; j++) {
                const element1 = element.sessions[j];
                if (element1.min_age_limit === 18) {
                    returnData += element1.available_capacity_dose2
                }

            }
        }
        if (returnData > 0) {
            this.setState({
                run18D2Alert: false
            })
            localStorage.removeItem("run18D2Alert")
            init_aud.pause()

            return true
            // alert("Click Ok to open COWIN Site")
        }
        return false
    }

    check45Dose1(data) {
        var returnData = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            for (let j = 0; j < element.sessions.length; j++) {
                const element1 = element.sessions[j];
                if (element1.min_age_limit === 45) {
                    returnData += element1.available_capacity_dose1
                }

            }
        }
        if (returnData > 0) {
            this.setState({
                run45D1Alert: false
            })
            localStorage.removeItem("run45D1Alert")
            init_aud.pause()

            return true
            // alert("Click Ok to open COWIN Site")
        }
        return false
    }

    check45Dose2(data) {
        var returnData = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            for (let j = 0; j < element.sessions.length; j++) {
                const element1 = element.sessions[j];
                if (element1.min_age_limit === 45) {
                    returnData += element1.available_capacity_dose2
                }

            }
        }
        if (returnData > 0) {
            this.setState({
                run45D2Alert: false
            })
            localStorage.removeItem("run45D2Alert")
            init_aud.pause()

            return true
            // alert("Click Ok to open COWIN Site")
        }
        return false
    }

    putData() {
        console.log(this.state.getName, this.state.getName.trim(), this.state.getFullAddress, this.state.getPincode)
        if (this.state.getName && this.state.getName.trim() && this.state.getFullAddress && this.state.getPincode) {
            var secret = new fernet.Secret("dkAJjbL09BHfPIygCptJUd6SBcP7BK4y-oZpDimNR2I=");
            var token = new fernet.Token({
                secret: secret,
            })
            this.setState({
                openAdd: false
            })

            Service.get('https://geocode.search.hereapi.com/v1/geocode?q=' + this.state.getFullAddress + ', ' + this.state.getPincode + "&apiKey=JJTU9Kq3vbmV7G0pP0S8JPwutSHHWkQ0M7Sm2j6DPUQ", {
                // headers: {
                //     Authorization: "Bearer JJTU9Kq3vbmV7G0pP0S8JPwutSHHWkQ0M7Sm2j6DPUQ"
                // },

            })
                .then(res => {
                    if (res.data.items.length !== 0) {
                        Service.post('https://bb-covid-app.el.r.appspot.com/bb/covid/pro/update_data/', {
                            headers: {

                            },
                            data: {
                                data: token.encode(JSON.stringify({
                                    email: getCookie("bb_covid19_ma"),
                                    name: this.state.getName,
                                    full_address: this.state.getFullAddress,
                                    lat: res.data.items[0].position.lat,
                                    lng: res.data.items[0].position.lng,
                                    pincode: this.state.getPincode
                                }))
                            }
                        })
                            .then(res => {
                                // console.log(res.data)
                                alert("It will take upto 2 minites to update your pincode vaccine status! Please refresh the page after 2 minutes.")
                                window.location.reload()
                            })
                            .catch(error => {

                                console.log("error: ", error);
                            });
                    } else {
                        this.setState({
                            openAdd: true
                        })
                        alert("Re-enter your address again because we can't find your location")
                    }

                    // alert("It will take upto 2 minites to update your pincode vaccine status!")
                })
                .catch(error => {
                    this.setState({
                        openAdd: true
                    })
                    alert("Re-enter your address again because we can't find your location")
                    console.log("error: ", error);
                });



        } else {
            alert("Fill all the fileds!")
        }

    }

    putPinData() {
        if (this.state.getPincode.length === 6) {
            this.setState({
                openPin: false
            })
            var secret = new fernet.Secret("dkAJjbL09BHfPIygCptJUd6SBcP7BK4y-oZpDimNR2I=");
            var token = new fernet.Token({
                secret: secret,
            })
            Service.post('https://bb-covid-app.el.r.appspot.com/bb/covid/pro/update_pin/', {
                headers: {

                },
                data: {
                    data: token.encode(JSON.stringify({
                        email: getCookie("bb_covid19_ma"),
                        pincode: this.state.getPincode
                    }))
                }
            })
                .then(res => {
                    // console.log(res.data)
                    alert("It will take upto 2 minutes to update your pincode vaccine status!. Please refresh the page after 2 minutes.")
                    window.location.reload()
                })
                .catch(error => {

                    console.log("error: ", error);
                });
        } else {
            alert("Not a valid pincode!")
        }
    }

    _setMapStyle(mapProps, map) {
        map.setOptions({
            styles: mapStyle
        })


        this.fetchPlaces(mapProps, map)
    }

    // geoFetch(element1, element) {
    //     const e1 = element1
    //     const e = element
    //     Geocode.fromAddress(e1 + "," + e.state).then(
    //         (response) => {
    //             const { lat, lng } = response.results[0].geometry.location;
    //             console.log(response.results[0]);

    //             // if (!response.results.formatted_address) {
    //             //     alert(JSON.stringify(response.results))
    //             // }

    //             district_lat_lng.push({
    //                 name: response.results[0].address_components[0].long_name,

    //                 lat: lat,
    //                 lng: lng
    //             })
    //             console.log(JSON.stringify(district_lat_lng))

    //         },
    //         (error) => {
    //             Geocode.fromAddress(element.state).then(
    //                 (response) => {
    //                     const { lat, lng } = response.results[0].geometry.location;
    //                     console.log(response.results[0]);

    //                     district_lat_lng.push({
    //                         name: response.results[0].address_components[0].long_name,

    //                         lat: lat,
    //                         lng: lng
    //                     })
    //                     console.log(JSON.stringify(district_lat_lng))

    //                 },
    //                 (error) => {
    //                     console.error(error);
    //                 });
    //         }
    //     );
    // }

    fetchPlaces(mapProps, map) {
        let points = data
        const { google } = mapProps;
        // console.log("qwerty", points)
        let googleBounds = new google.maps.LatLngBounds();
        for (var i = 0; i < points.length; i++) {
            // console.log(parseFloat(points[i].lat))
            googleBounds.extend({ lat: parseFloat(points[i].lat), lng: parseFloat(points[i].lng) });
        }
        // console.log(googleBounds)
        map.fitBounds(googleBounds);
        // ..
    }

    available18Dose(data) {
        var returnData = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.min_age_limit === 18) {
                returnData += element.available_capacity
            }

        }
        return returnData
    }

    available45Dose(data) {
        var returnData = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.min_age_limit === 45) {
                returnData += element.available_capacity
            }
        }
        return returnData
    }

    availableDose(data) {
        var returnData = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            returnData += element.available_capacity
        }
        return returnData
    }

    doseFee(data) {
        var returnData = null
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (returnData) {
                returnData += ", " + element.vaccine + " (Rs " + element.fee + ")"
            } else {
                returnData = element.vaccine + " (Rs " + element.fee + ")"
            }

        }
        return returnData
    }

    render() {


        const { classes } = this.props;

        const handleVaccClose = () => {
            this.setState({
                openVacc: false
            })
        };

        const handleAlertClose = () => {
            this.setState({
                openAlert: false
            })
        };

        const handleAddClose = () => {
            this.setState({
                openAdd: false
            })
        };

        const handlePinClose = () => {
            this.setState({
                openPin: false
            })
        };

        const handleNameChange = (event) => {
            this.setState({ getName: event.target.value });
        };
        const handleAddressChange = (event) => {
            this.setState({ getFullAddress: event.target.value });
        };
        const handlePinChange = (event) => {
            this.setState({ getPincode: event.target.value });
        };


        return (
            (this.state.isLoading) ? ("Authenticating...") : (
                <div>
                    <Map
                        // style={{ height: '93.5%' }}
                        google={this.props.google}
                        // onReady={this.fetchPlaces}
                        onReady={(mapProps, map) => this._setMapStyle(mapProps, map)}>

                        {this.state.data.map((item) =>
                            <Circle
                                // radius={item.data.confirmed / 3}
                                radius={(2500 * ((item.data.confirmed - (item.data.recovered + item.data.deceased)) / 1000) > 70000) ? 70000 :
                                    (2500 * (((item.data.confirmed - (item.data.recovered + item.data.deceased))) / 1000)) < 6000 ?
                                        6000 : (2500 * (((item.data.confirmed - (item.data.recovered + item.data.deceased))) / 1000))}
                                // radius={50000}
                                center={{ lat: parseFloat(item.lat), lng: parseFloat(item.lng) }}
                                // onMouseover={() => console.log('mouseover')}
                                onClick={() => alert("District: " + item.name + "\nState: " + item.state +
                                    "\nConfirmed: " + item.data.confirmed + "\nActive: " + (item.data.confirmed - (item.data.recovered + item.data.deceased)) + "\nRecovered: " + item.data.recovered +
                                    "\nTested: " + item.data.tested + "\nVaccinated: " + item.data.vaccinated
                                )}
                                // onMouseout={() => console.log('mouseout')}
                                strokeColor='#0000ff'
                                strokeOpacity={0.7}
                                strokeWeight={1}
                                fillColor='#242166'
                                fillOpacity={(0.2 + (((item.data.confirmed - (item.data.recovered + item.data.deceased)) / 250000))) > 0.7 ? 0.7 : (0.2 + (((item.data.confirmed - (item.data.recovered + item.data.deceased))) / 250000))}
                                key={item.name + item.state}
                            />

                        )}
                        {this.state.emp.map((item) =>
                            <Marker
                                title={item.name}
                                key={item.name}
                                onClick={(e) => {
                                    alert("Name: " + item.name + "\nEmail: " + item.email + "\nPrimary Contact: " + item.contact_pri +
                                        "\nSecondary Contact: " + item.contact_sec + "\nFull Address: " + item.full_address);
                                    // this.getPinData(item, true)

                                }}
                                name={item.name}
                                position={{ lat: parseFloat(item.lat), lng: parseFloat(item.lng) }}

                            />

                        )}

                    </Map>

                    <div style={{
                        position: 'absolute',
                        bottom: '0px',
                        left: '0px',
                        padding: '20px',
                    }}>
                        {(this.state.emp.length === 1) ? (
                            <Box display="flex" justifyContent="center" m={1} p={1} >
                                <Button variant="contained" color="primary" onClick={() => {
                                    if (this.state.emp.length === 1) {
                                        this.setState({
                                            // selectedData: item,
                                            openVacc: true
                                        })

                                    }
                                }}>
                                    Vaccine Slot Assistance
</Button>
                            </Box>
                        ) : (null)}


                    </div>

                    <Dialog fullScreen open={this.state.openVacc} onClose={handleVaccClose} TransitionComponent={Transition}>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleVaccClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography className={classes.title} variant="h6">
                                    {this.state.selectedData.name}
                                </Typography>

                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <Box display="flex" justifyContent="center" bgcolor="background.paper">
                            <p style={{color: 'red'}}>The only solution to fight corona is to vaccinate, vaccinate and vaccinate. This will help curtail the spread of infection to some extent and save fatalities to a great extent.</p>
                              
                            </Box>
                            <Box display="flex" justifyContent="center"  bgcolor="background.paper">
                                <p style={{color: 'green'}}>Data will refresh automatically once the data is changed on our servers. Enable voice assistance, and it will notify of availability while you continue working.</p>

                            </Box>

                            <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
                                <Button variant="contained" color="primary" onClick={() => {
                                    init_aud.play()
                                    this.setState({
                                        openAlert: true
                                    }, () => {
                                        setTimeout(() => alert("Choose the age and dose of your need. Voice assistance will speak to you once the dose is available. But you need to keep this page opened in this browser.") , 300)
                                        
                                    })
                                }}>
                                    Enable Voice Assistant alert while we keep working
            </Button>
                            </Box>

                            <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
                                <Button variant="contained" color="primary" onClick={() => {

                                    this.setState({
                                        openPin: true
                                    })
                                }}>
                                    Change Pincode
            </Button>
                            </Box>

                            <MaterialTable
                                title={"Your Pincode: " + this.state.fullPinData.pin_code + " (Auto Refresh Enabled)"}
                                columns={[
                                    { title: 'Center Name', field: 'name' },
                                    { title: 'Address', field: 'address' },
                                    {
                                        title: 'Available 18+', field: 'avail_18',
                                        // render: rowData => this.available18Dose(rowData.sessions)
                                    },
                                    {
                                        title: 'Available 45+', field: 'avail_45',
                                        // render: rowData => this.available45Dose(rowData.sessions)
                                    },
                                    {
                                        title: 'Fee', field: 'fee_type',
                                        render: rowData => rowData.fee_type === 'Free' ? "Free" : this.doseFee(rowData.vaccine_fees)
                                    },

                                ]}
                                data={this.state.vaccData}
                                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                detailPanel={rowData => {
                                    return (
                                        <MaterialTable
                                            title={rowData.name}
                                            columns={[
                                                { title: 'Date', field: 'date' },
                                                { title: 'vaccine', field: 'vaccine' },
                                                { title: 'Minimum Age', field: 'min_age_limit' },
                                                { title: 'available_capacity_dose1', field: 'available_capacity_dose1' },
                                                { title: 'available_capacity_dose2', field: 'available_capacity_dose2' },

                                            ]}
                                            data={rowData.sessions}
                                        />
                                    )
                                }}
                            />
                        </DialogContent>
                        <DialogActions>

                            <Button onClick={handleVaccClose} color="primary" autoFocus>
                                Close
          </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog onClose={handleAlertClose} aria-labelledby="simple-dialog-title" open={this.state.openAlert}>
                        <DialogTitle id="simple-dialog-title">Select alert process of your choise to run</DialogTitle>
                        <List>
                            {(!this.state.run18D1Alert) ? (
                                <ListItem button onClick={() => {
                                    init_aud.pause()
                                    localStorage.setItem("run18D1Alert", "true");
                                    this.setState({ run18D1Alert: true })
                                }}>

                                    <ListItemText primary="18+ Dose 1" />
                                </ListItem>
                            ) : (
                                <ListItem button onClick={() => {
                                    init_aud.pause()
                                    localStorage.removeItem("run18D1Alert");
                                    this.setState({ run18D1Alert: false })
                                }}>

                                    <ListItemText primary="Running - 18+ Dose 1" />
                                </ListItem>
                            )}
                            {(!this.state.run18D2Alert) ? (
                                <ListItem button onClick={() => {
                                    init_aud.pause()
                                    localStorage.setItem("run18D2Alert", "true");
                                    this.setState({ run18D2Alert: true })
                                }}>

                                    <ListItemText primary="18+ Dose 2" />
                                </ListItem>
                            ) : (
                                <ListItem button onClick={() => {
                                    init_aud.pause()
                                    localStorage.removeItem("run18D2Alert");
                                    this.setState({ run18D2Alert: false })
                                }}>

                                    <ListItemText primary="Running - 18+ Dose 2" />
                                </ListItem>
                            )}
                            {(!this.state.run45D1Alert) ? (
                                <ListItem button onClick={() => {
                                    init_aud.pause()
                                    localStorage.setItem("run45D1Alert", "true");
                                    this.setState({ run45D1Alert: true })
                                }}>

                                    <ListItemText primary="45+ Dose 1" />
                                </ListItem>
                            ) : (
                                <ListItem button onClick={() => {
                                    init_aud.pause()
                                    localStorage.removeItem("run45D1Alert");
                                    this.setState({ run45D1Alert: false })
                                }}>

                                    <ListItemText primary="Running - 45+ Dose 1" />
                                </ListItem>
                            )}
                            {(!this.state.run45D2Alert) ? (
                                <ListItem button onClick={() => {
                                    init_aud.pause()
                                    localStorage.setItem("run45D2Alert", "true");
                                    this.setState({ run45D2Alert: true })
                                }}>

                                    <ListItemText primary="45+ Dose 2" />
                                </ListItem>
                            ) : (
                                <ListItem button onClick={() => {
                                    init_aud.pause()
                                    localStorage.removeItem("run45D2Alert");
                                    this.setState({ run45D2Alert: false })
                                }}>

                                    <ListItemText primary="Running - 45+ Dose 1" />
                                </ListItem>
                            )}




                        </List>
                    </Dialog>

                    <Dialog onClose={handleAddClose} aria-labelledby="simple-dialog-title" open={this.state.openAdd}>
                        <DialogTitle id="simple-dialog-title">We don't have your information. Please fill the form below</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Note : Please enter correct details so that we can help you better. Also please fill you complete address in the second feild.
          </DialogContentText>
                            <TextField id="name" style={{ margin: '5px' }} fullWidth label="Full Name" variant="outlined"
                                onChange={handleNameChange} />
                            <TextField id="address" style={{ margin: '5px' }} multiline rows={4} fullWidth label="Please Enter your correct complete address" variant="outlined"
                                onChange={handleAddressChange} />
                            <TextField id="pincode" type="number" style={{ margin: '5px' }} fullWidth label="Pincode" variant="outlined"
                                onChange={handlePinChange} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAddClose} color="primary">
                                Cancel
          </Button>
                            <Button onClick={() => this.putData()} color="primary">
                                Submit
          </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog onClose={handlePinClose} aria-labelledby="simple-dialog-title" open={this.state.openPin}>
                        <DialogTitle id="simple-dialog-title">Change Pincode</DialogTitle>
                        <DialogContent>
                            {/* <DialogContentText>
                                Note : Please enter correct details so that we can help you better. Also please fill you complete address in the second feild.
          </DialogContentText> */}
                            <TextField id="name" style={{ margin: '5px' }} fullWidth label="Pincode" variant="outlined"
                                onChange={handlePinChange} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handlePinClose} color="primary">
                                Cancel
          </Button>
                            <Button onClick={() => this.putPinData()} color="primary">
                                Submit
          </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            )

        )
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyDqNmZ2fMtyJacfs_vCd6k7z2V_36J4nEM')
})(withStyles(useStyles)(MapView));