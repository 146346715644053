// import logo from './logo.svg';
import './App.css';
import MapView from './Map/Map';

function App() {
  return (
    
    <MapView />
  );
}

export default App;
